<template>
  <transition
    @leave="leave"
  >
  <!-- v-if="!load" -->
    <Loading
      v-if="!load"
      :overflow="true"
    ></Loading>
  </transition>

  <!-- Main content -->
  <div
    class="overlay-wrapper position-fixed top-0 page-center-wrapper full-width-wrapper
    d-flex flex-column align-items-center bg-primary"
  >
    <!-- close Overlay button -->
    <div class="position-absolute close-overlay-button-wrapper bg-white me-auto ms-auto mt-5">
        <a
          class="w-100 h-100 cursor-pointer text-decoration-none"
          aria-label="Close"
          @click.prevent="closeOverlay()"
        >
          <div class="w-100 h-100 product-overlay d-flex justify-content-center align-items-center">
            <i class="material-icons md-24 md-dark">close</i>
          </div>
        </a>
    </div>
    <!-- close Overlay button -->
    <div class="wrapper container-fluid mt-nav text-center">
      <!-- exhibtion details -->
      <h1 class="text-uppercase mt-3 mt-lg-0">
        {{ exhibition.Venue }}
      </h1>
      <h2 class="h3 mt-5 mt-lg-3">
        {{ exhibition.Period }}
      </h2>
      <template
        v-for="(artist, index) in exhibition.artists"
        :key="artist.Name"
      >
        <h2 class="h3 me-2">
          <span class="">Artist : </span>
          {{ artist.Name }}
          <span v-if="index !== exhibition.artists.length - 1">,</span>
        </h2>
      </template>
      <h2 class="h3">
        <span>Curator : </span>
        <span v-if="exhibition.Curator1" class="me-2">
          {{ exhibition.Curator1 }}
        </span>
        <span v-if="exhibition.Curator2" class="span me-2">
          {{ exhibition.Curator2 }}
        </span>
        <span v-if="exhibition.Curator3" class="span me-2">
          {{ exhibition.Curator3 }}
        </span>
      </h2>
      <!-- exhibition details -->
      <!-- Swiper -->
      <div class="d-flex mt-5 mb-7">
        <button class="btn p-0 p-lg-3" @click="prev">
          <i class="material-icons md-36 md-lg-48">chevron_left</i>
        </button>
        <swiper
          :loop="true"
          :breakpoints="swiperBreakpoints.breakpoints"
          @swiper="onSwiper"
        >
          <swiper-slide v-for="(image, index) in images" :key="image.id">
            <div class="large-img-wrapper">
              <img
                :src="image.formats.large.url"
                alt=""
                @load="onImgLoad(index)"
              >
            </div>
          </swiper-slide>
          <template
            v-for="(video, index) in videos" :key="'video' + index"
          >
            <swiper-slide
              v-if="video"
            >
              <!-- <div class="large-img-wrapper"> -->
                <iframe
                  :src="'https://player.vimeo.com/video/' + video"
                  width="100%" height="100%"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
                  @load="onVideoLoad(index)"
                >
                </iframe>
              <!-- </div> -->
            </swiper-slide>
          </template>
        </swiper>
        <button class="btn p-0 p-lg-3" @click="next">
          <i class="material-icons md-36 md-lg-48">chevron_right</i>
        </button>
      </div>
      <!-- Swiper -->
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';
import { reactive } from 'vue';
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
// Import Swiper and modules
import SwiperCore, { Navigation } from 'swiper/core';
import Loading from '@/components/front/LoadingGeneral.vue';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';

SwiperCore.use([Navigation]);

export default {
  props: ['exhibition', 'images', 'videos'],
  data() {
    return {
      swiper: '',
      imgLoad: false,
      videoLoad: false,
      imgLoadLog: null,
      videoLoadLog: null,
    };
  },
  components: {
    Loading,
    Swiper,
    SwiperSlide,
  },
  setup(props) {
    const swiperBreakpoints = reactive({
      breakpoints: {
        575: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        767: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        991: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        1190: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        1430: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
      },
    });
    return {
      props,
      swiperBreakpoints,
    };
  },
  computed: {
    load() {
      console.log(`image status: ${this.imgLoad}`);
      console.log(`video status: ${this.videoLoad}`);
      return this.imgLoad && this.videoLoad;
    },
  },
  methods: {
    /**
     * Page Load effects
     */
    leave(el, done) {
      gsap.to(el, {
        duration: 0.3,
        delay: 1,
        opacity: 0,
        ease: 'ease-out',
        onComplete: done,
      });
      setTimeout(() => {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('overflow-hidden');
      }, 1000);
    },
    /**
     * Main methods
     */
    onImgLoad(index) {
      const len = this.images.length;
      this.imgLoadLog.add(index);
      if (this.imgLoadLog.size === len) {
        this.imgLoad = true;
      }
    },
    onVideoLoad(index) {
      this.videoLoadLog.add(index);
      if (this.videoLoadLog.size === this.videos.length) {
        this.videoLoad = true;
      }
    },
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    prev() {
      this.swiper.slidePrev();
    },
    next() {
      this.swiper.slideNext();
    },
    closeOverlay() {
      this.$emit('close-overlay');
      const body = document.getElementsByTagName('body')[0];
      body.classList.remove('overflow-hidden');
    },
  },
  mounted() {
    console.clear();
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('overflow-hidden');
    this.imgLoadLog = new Set();
    this.videoLoadLog = new Set();
  },
};
</script>
