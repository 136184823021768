<template>
  <transition
    @leave="leave"
  >
  <!-- v-if="!load" -->
    <Loading
      v-if="!load"
      :overflow="true"
      :load-bg="currentLoadingBg"
    ></Loading>
  </transition>

  <!-- Main content -->
  <div class="container-fluid mt-nav mb-7">
    <div>
      <div class="row">
        <div
          data-aos="fade-up"
          data-aos-delay="1500"
          data-aos-duration="500"
          class="col-12"
        >
          <h1 >phy - xhibition | {{ city[0].City }}</h1>
          <h2 class="h4 text-uppercase">
            {{ city[0].SchoolName }}
          </h2>
        </div>
        <!-- Google Map for Tallinn -->
        <div
          data-aos="fade-up"
          data-aos-delay="1900"
          data-aos-duration="500"
          v-if="this.slug === 'tallinn'"
          class="col-12 col-lg-7 mt-5 mb-3"
        >
          <div
            class="map-wrapper overflow-hidden"
          >
            <iframe
              style=""
              src="https://www.google.com/maps/d/u/0/embed?mid=1Tf8nyF3W2UGfICQ7AsTmXr5qC1sPyr1K"
            >
            </iframe>
          </div>
        </div>
        <!-- Google Map -->
        <!-- Exhibition Venue Details -->
        <div
          data-aos="fade-up"
          data-aos-delay="1900"
          data-aos-duration="500"
        >
          <template v-for="(exhibition, index) in city[0].phy_xhibitions" :key="exhibition.Venue">
            <template
              v-if="exhibition.Type === 'Exhibition'"
            >
              <div
                class="col-12 col-lg-7 mt-4"
              >
                <h3>
                  {{ exhibition.Venue }}
                </h3>
                <div class="en">
                  {{ exhibition.Address }}
                </div>
                <div class="en">
                  {{ exhibition.Period }}
                </div>
                <div class="en">
                  {{ exhibition.OpeningHours }}
                </div>
                <span class="">Artist : </span>
                <span v-for="(artist, index) in exhibition.artists" :key="artist.Name" class="en">
                  <span class="span me-2">
                    {{ artist.Name }}
                    <span v-if="index !== exhibition.artists.length - 1">,</span>
                  </span>
                </span>
                <div class="en">
                  <span v-if="exhibition.Curator1" class="">Curator(s): </span>
                  <span v-if="exhibition.Curator1" class="span me-2">
                    {{ exhibition.Curator1 }}
                  </span>
                  <span v-if="exhibition.Curator2" class="span me-2">
                    {{ exhibition.Curator2 }}
                  </span>
                  <span v-if="exhibition.Curator3" class="span me-2">
                    {{ exhibition.Curator3 }}
                  </span>
                </div>
                <a
                  class="btn btn-primary mt-2"
                  v-if="exhibition.Website"
                  :href="exhibition.Website"
                  target="_blank"
                >
                  <div class="d-flex align-items-center body-regular text-uppercase">
                    <span class="h4 mb-0">Link to artwork</span>
                    <img src="../../assets/icon/right-arrow.svg" alt="" class="link-icon ms-4" />
                  </div>
                </a>
                <button
                  class="btn btn-primary mt-2"
                  v-if="exhibition.ImageDocu"
                  @click="goToPhyxhibitionDocu(index)"
                >
                  <div class="d-flex align-items-center body-regular text-uppercase">
                    <span class="h4 mb-0">view</span>
                    <img src="../../assets/icon/right-arrow.svg" alt="" class="link-icon ms-4" />
                  </div>
                </button>
              </div>
            </template>
          </template>
        </div>
        <!-- Exhibition Venue Details -->

        <!-- Google Map for HK -->
        <div
          v-if="this.slug === 'hong-kong'"
          class="col-12 col-lg-7 mt-5 mb-3"
        >
          <div
            class="map-wrapper overflow-hidden"
          >
            <iframe
              style=""
              src="https://www.google.com/maps/d/embed?mid=13Ya8FT8jIh8gSVPABvMaEv3ZkzZxiR6U"
            >
            </iframe>
          </div>
        </div>
        <!-- Google Map -->

        <!-- CITYA Screening Details -->
        <h2
          v-if="haveScreening"
          class="mt-5 mb-3"
        >
          CITYA screening
        </h2>
        <template v-for="(exhibition, index) in city[0].phy_xhibitions" :key="exhibition.Venue">
          <div
            v-if="exhibition.Type === 'Screening'"
            :id="`screening${index}`"
          >
            <div
              class="col-12 col-lg-7 mb-4"
              data-aos="fade-up"
              :data-aos-anchor="`#screening${index}`"
              data-aos-duration="500"
              data-aos-anchor-placement="bottom-bottom"
            >
              <h3 class="">
                {{ exhibition.Venue }}
              </h3>
              <div class="en">
                {{ exhibition.Address }}
              </div>
              <div class="en">
                {{ exhibition.Period }}
              </div>
              <div class="en">
                {{ exhibition.OpeningHours }}
              </div>
              <span
                v-if="exhibition.artists.length > 0 || exhibition.ArtistsListText"
                class=""
              >
                Artist(s):&nbsp;
              </span>
              <span v-if="exhibition.ArtistsListText" class="span me-2">
                {{ exhibition.ArtistsListText }}
              </span>
              <span
                v-for="(artist, index) in exhibition.artists"
                :key="artist.Name" class="en">
                <span class="span me-2">
                  {{ artist.Name }}
                  <span v-if="index !== exhibition.artists.length - 1">,</span>
                </span>
              </span>
              <div class="en">
                <span v-if="exhibition.Curator1" class="">Curator(s) : </span>
                <span v-if="exhibition.Curator1" class="span me-2">
                  {{ exhibition.Curator1 }}
                </span>
                <span v-if="exhibition.Curator2" class="span me-2">
                  {{ exhibition.Curator2 }}
                </span>
                <span v-if="exhibition.Curator3" class="span me-2">
                  {{ exhibition.Curator3 }}
                </span>
              </div>
              <a
                class="btn btn-primary mt-2"
                v-if="exhibition.Website"
                :href="exhibition.Website"
                target="_blank"
              >
                <div class="d-flex align-items-center body-regular text-uppercase">
                  <span class="h4 mb-0">Website</span>
                  <img src="../../assets/icon/right-arrow.svg" alt="" class="link-icon ms-4" />
                </div>
              </a>
              <button
                class="btn btn-primary mt-2"
                v-if="exhibition.ImageDocu"
                @click="goToPhyxhibitionDocu(index)"
              >
                <div class="d-flex align-items-center body-regular text-uppercase">
                  <span class="h4 mb-0">view</span>
                  <img src="../../assets/icon/right-arrow.svg" alt="" class="link-icon ms-4" />
                </div>
              </button>
            </div>
          </div>
        </template>
        <!-- CITYA Screening Details -->
      </div>
    </div>
  </div>
  <!-- Overlay Exhibition Details -->
  <transition name="fade">
    <PhyxhibitionOverlay
      v-if="detailsOverlay"
      :exhibition="SelectedExhibition"
      :images="SelectedExhibitionImages"
      :videos="SelectedExhibitionVideos"
      @close-overlay="closeDetailsOverlay"
    >
    </PhyxhibitionOverlay>
  </transition>
  <!-- Overlay Exhibition Details -->
  <!-- Footer -->
  <Footer
    :links="footerLinks"
    :bottom="scrollToBottom"
  ></Footer>
  <!-- Footer -->
</template>

<script>
import gql from 'graphql-tag';
import gsap from 'gsap';
import AOS from 'aos';
import emitter from '@/components/tools/emitter';
import Loading from '@/components/front/LoadingCity.vue';
import PhyxhibitionOverlay from '@/components/front/PhyxhibitionOverlay.vue';
import Footer from '@/components/front/Footer.vue';
import 'aos/dist/aos.css';

export default {
  name: 'City',
  data() {
    return {
      slug: '',
      apolloLoad: false,
      currentLoadingBg: 'bg-citya-day',
      city: [
        {
          City: '',
          SchoolName: '',
          phy_xhibitions: {
            Venue: '',
            Slug: '',
            Address: '',
            Period: '',
            OpeningHours: '',
            Website: '',
            Type: '',
            ArtistsListText: '',
            artists: [
              {
                Name: '',
              },
            ],
            Curator1: '',
            Curator2: '',
            Curator3: '',
          },
        },
      ],
      haveScreening: false,
      detailsOverlay: false,
      SelectedExhibition: {},
      SelectedExhibitionImages: [],
      SelectedExhibitionVideos: [],
      currentCity: '',
      currentHour: null,
      footerLinks: [],
      scrollToBottom: false,
    };
  },
  components: {
    Loading,
    PhyxhibitionOverlay,
    Footer,
  },
  watch: {
    city: {
      deep: true,
      handler() {
        emitter.emit('emit-city', this.city[0].City);
        // check if there is screening programmes
        for (let i = 0; i < this.city[0].phy_xhibitions.length; i += 1) {
          if (this.city[0].phy_xhibitions[i].Type === 'Screening') {
            this.haveScreening = true;
          }
        }

        // update footer link
        const linkData1 = {
          City: this.city[0].City,
          Slug: this.slug,
          Text: `BACK TO ${this.city[0].City}`,
          Link: `/city/${this.slug}`,
        };
        const linkData2 = {
          City: this.city[0].City,
          Slug: this.slug,
          Text: `E-XHIBITION | ${this.city[0].City}`,
          Link: `/city/${this.slug}/artists`,
        };
        this.footerLinks.push(linkData1, linkData2);
        if (this.city[0].City === 'Hong Kong') {
          const linkData3 = {
            City: this.city[0].City,
            Slug: this.slug,
            Text: `YOUTH CURRENT | ${this.city[0].City}`,
            Link: `/city/${this.slug}/youth-current-artists`,
          };
          this.footerLinks.push(linkData3);
        }
      },
    },
  },
  apollo: {
    city: {
      query: gql`
        query Phyxhibition($Slug: String!) {
          cities(where: { Slug: $Slug }) {
            Slug
            City
            SchoolName
            phy_xhibitions {
              Venue
              Slug
              Address
              Period
              OpeningHours
              Website
              Type
              ArtistsListText
              artists {
                Name
              }
              Curator1
              Curator2
              Curator3
              ImageDocu
              DocuImages {
                id
                formats
              }
              DocuVideo1
              DocuVideo2
              DocuVideo3
            }
          }
        }
      `,
      variables() {
        return {
          Slug: this.$route.params.slug,
        };
      },
      update(data) {
        // console.log(data.cities);
        return data.cities;
      },
      error(error) {
        console.error("We've got an error!", error);
      },
      loadingKey: 'loadingQueriesCount',
      // watchLoading will be called whenever the loading state changes
      // eslint-disable-next-line no-unused-vars
      watchLoading(isLoading, countModifier) {
        // isLoading is a boolean
        // countModifier is either 1 or -1

        this.apolloLoad = false;
        if (this.currentLoadingBg !== 'bg-citya-day' && isLoading) {
          this.updateLoadBg();
        }
        // console.log('watch loading', isLoading, countModifier);
        if (!isLoading) {
          this.apolloLoad = true;
          console.log('load complete');
        }
      },
    },
  },
  computed: {
    load() {
      console.log(`apollo status: ${this.apolloLoad}`);
      AOS.init({ once: false, disable: 'phone' });
      AOS.refresh();
      return this.apolloLoad;
    },
  },
  methods: {
    /**
     * Page Load effects
     */
    updateLoadBg() {
      switch (this.$route.params.slug) {
        case 'hong-kong':
          this.currentLoadingBg = 'bg-hongkong-day';
          break;
        case 'macao':
          this.currentLoadingBg = 'bg-macau-day';
          break;
        case 'beijing':
          this.currentLoadingBg = 'bg-beijing-day';
          break;
        case 'san-francisco':
          this.currentLoadingBg = 'bg-sf-day';
          break;
        case 'tallinn':
          this.currentLoadingBg = 'bg-tallinn-day';
          break;
        case 'rome':
          this.currentLoadingBg = 'bg-rome-day';
          break;
        default:
          this.currentLoadingBg = 'bg-citya-day';
          break;
      }
    },
    removeLoadBg(lastCitySlug) {
      switch (lastCitySlug) {
        case 'hong-kong':
          return 'bg-hongkong-day';
        case 'macao':
          return 'bg-macau-day';
        case 'beijing':
          return 'bg-beijing-day';
        case 'san-francisco':
          return 'bg-sf-day';
        case 'tallinn':
          return 'bg-tallinn-day';
        case 'rome':
          return 'bg-rome-day';
        default:
          return 'bg-citya-day';
      }
    },
    leave(el, done) {
      gsap.to(el, {
        duration: 0.3,
        delay: 1,
        opacity: 0,
        ease: 'ease-out',
        onComplete: done,
      });
      setTimeout(() => {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('overflow-hidden');
      }, 1000);
    },
    /**
     * Main methods
     */
    goToExhibition() {
      this.$router.push(`/city/${this.slug}/artists`);
    },
    goToYoungCurrent() {},
    goToPhyxhibitionDocu(index) {
      // go to separate page
      // this.$router.push(
      //   `/city/${this.slug}/phy-xhibition/${this.city[0].phy_xhibitions[index].Slug}`,
      // );

      // props data to overlay
      this.SelectedExhibition = this.city[0].phy_xhibitions[index];
      this.SelectedExhibitionImages = this.city[0].phy_xhibitions[index].DocuImages;

      // add video to array if it's not null
      if (this.city[0].phy_xhibitions[index].DocuVideo1) {
        this.SelectedExhibitionVideos.push(this.city[0].phy_xhibitions[index].DocuVideo1);
      }
      if (this.city[0].phy_xhibitions[index].DocuVideo2) {
        this.SelectedExhibitionVideos.push(this.city[0].phy_xhibitions[index].DocuVideo2);
      }
      if (this.city[0].phy_xhibitions[index].DocuVideo3) {
        this.SelectedExhibitionVideos.push(this.city[0].phy_xhibitions[index].DocuVideo3);
      }
      this.detailsOverlay = true;
    },
    onScroll() {
      const windowY = window.scrollY;
      const intViewportHeight = window.innerHeight;
      // console.log(windowY);
      if (windowY + intViewportHeight >= document.documentElement.offsetHeight) {
        this.scrollToBottom = true;
      } else {
        this.scrollToBottom = false;
      }
    },
    closeDetailsOverlay() {
      this.detailsOverlay = false;
    },
  },
  mounted() {
    console.clear();
    // AOS.init();
    this.slug = this.$route.params.slug;
    window.addEventListener('scroll', this.onScroll);
    this.updateLoadBg();
  },
  unmounted() {
    window.removeEventListener('scroll', this.onScroll);
  },
};
</script>
